<template>
  <v-row class="match-height">
    <!-- horizontal -->
    <v-col cols="12" md="12">
      <v-card>
        <v-simple-table dense :loading="loading" class="elevation-1">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Tagihan Upah Bulanan</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-col cols="2" md="2">
                <v-autocomplete
                  class="mt-6"
                  v-model="editedItem.bulan"
                  :items="bulans"
                  dense
                  item-text="text"
                  item-value="value"
                  outlined
                  @change="getDataFromApi"
                ></v-autocomplete>
              </v-col>
              <v-col cols="2" md="2">
                <v-text-field
                  class="mt-6"
                  type="number"
                  v-model="editedItem.tahun"
                  dense
                  outlined
                  @keyup="getDataFromApi"
                ></v-text-field>
              </v-col>
              <v-col cols="2" md="2">
                <v-text-field
                  label="Nama/ kode / bagian/ wilayah"
                  v-model="filter"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-btn color="primary" small @click="getDataFromApi()">Lihat</v-btn>
              <!-- &nbsp;
              <v-btn color="secondary" small @click="cetak()">Cetak</v-btn> -->
              &nbsp;
              <v-btn color="secondary" small @click="export_excel()">Export Excel</v-btn>
            </v-toolbar>
          </template>

          <template v-slot:default>
            <thead>
              <tr>
                <th style="text-align: center">NO</th>
                <th style="text-align: center">ANGGOTA</th>
                <th style="text-align: center">BAGIAN</th>
                <th style="text-align: center">WILAYAH</th>
                <th style="text-align: center">NAMA ARISAN</th>
                <th style="text-align: center">TOTAL ARISAN</th>
                <th style="text-align: center">CICILAN</th>
                <th style="text-align: center">TOTAL CICILAN</th>
                <th style="text-align: center">TOTAL</th>
                <th style="text-align: center">AKSI</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in desserts" :key="index">
                <td class="text-center">{{ index + 1 }}</td>
                <td>{{ item.nama + ' (' + item.kode_anggota + ')' }}</td>
                <td>{{ item.divisi }}</td>
                <td>{{ item.wilayah }}</td>
                <td>{{ item.arisan.nama }}</td>
                <td style="text-align: right">
                  {{ formatMoney(item.arisan.nominal) }}
                </td>
                <td>{{ item.cicilan.nama }}</td>
                <td style="text-align: right">
                  {{ formatMoney(item.cicilan.nominal) }}
                </td>
                <td style="text-align: right">
                  {{ formatMoney(parseFloat(item.arisan.nominal) + parseFloat(item.cicilan.nominal)) }}
                </td>
                <td>
                  <v-checkbox
                    v-model="selected"
                    v-if="item.sudah_bayar === null"
                    label="Bayar"
                    :value="item.id"
                  ></v-checkbox>
                  <span v-else style="background: green; border-radius: 3px; padding: 5px 10px; color: white"
                    >Sudah Bayar</span
                  >
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="5" style="text-align: center">TOTAL</td>
                <td style="text-align: right">
                  {{ formatMoney(footer.totalArisan) }}
                </td>
                <td></td>
                <td style="text-align: right">
                  {{ formatMoney(footer.totalCicilan) }}
                </td>
                <td style="text-align: right">
                  {{ formatMoney(footer.total) }}
                </td>
                <td>
                  <v-checkbox class="mt-4" v-model="selectedAll" @click="selectAll()" label="Pilih Semua"></v-checkbox>
                  <v-btn class="mb-2" color="primary" :loading="loadingSimpan" @click="simpan()">Simpan</v-btn>
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<style scoped>
table tbody tr td,
table thead tr th,
table tfoot tr td {
  border: 1px solid grey;
}
</style>

<script>
import axios from 'axios'

const apiRoot = process.env.VUE_APP_APIURL

export default {
  data() {
    return {
      desserts: [],
      loading: true,
      footer: {
        totalArisan: 0,
        totalCicilan: 0,
        total: 0,
      },
      bulans: [
        {
          text: 'Januari',
          value: '01',
        },
        {
          text: 'Februari',
          value: '02',
        },
        {
          text: 'Maret',
          value: '03',
        },
        {
          text: 'April',
          value: '04',
        },
        {
          text: 'Mei',
          value: '05',
        },
        {
          text: 'Juni',
          value: '06',
        },
        {
          text: 'Juli',
          value: '07',
        },
        {
          text: 'Agustus',
          value: '08',
        },
        {
          text: 'September',
          value: '09',
        },
        {
          text: 'Oktober',
          value: '10',
        },
        {
          text: 'November',
          value: '11',
        },
        {
          text: 'Desember',
          value: '12',
        },
      ],
      editedItem: {
        tahun: parseFloat(new Date().toJSON().slice(0, 4)),
        bulan: new Date().toJSON().slice(5, 7),
      },
      filter: '',
      selected: [],
      selectedAll: false,
      loadingSimpan: false,
    }
  },
  created() {
    this.getDataFromApi()
  },
  computed: {
    token() {
      return this.$store.getters.token
    },
  },
  methods: {
    getDataFromApi() {
      this.$store.dispatch('loading', true)
      const headers = {
        Authorization: this.token,
      }
      axios
        .get(
          `${apiRoot}/api/Arisan/getTagihanAnggota_bulanan/token/app/${this.editedItem.bulan}/${this.editedItem.tahun}/${this.filter}`,
          {
            headers,
          },
        )
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.selected = []
            this.desserts = response.data.data

            this.footer.totalArisan = 0
            this.footer.totalCicilan = 0
            this.footer.total = 0
            response.data.data.forEach(item => {
              this.footer.totalArisan += parseFloat(item.arisan.nominal)
              this.footer.totalCicilan += parseFloat(item.cicilan.nominal)
              this.footer.total += parseFloat(item.arisan.nominal) + parseFloat(item.cicilan.nominal)
            })
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    selectAll() {
      if (this.selectedAll === true) {
        this.selected = []
        this.desserts.forEach(item => {
          if (item.sudah_bayar === null) {
            this.selected.push(item.id)
          }
        })
      } else {
        this.selected = []
      }
    },
    simpan() {
      this.loadingSimpan = true
      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('datas', this.selected)
      form.append('tanggal', `${this.editedItem.tahun}-${this.editedItem.bulan}-01`)
      form.append('jenis_upah', 'BULANAN')
      axios
        .post(`${apiRoot}/api/Arisan/saveBatchTagihan`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.getDataFromApi()
            this.selectedAll = false
            this.selected = []
          } else {
            alert(response.data.message)
          }
          this.loadingSimpan = false
        })
        .catch(error => {
          alert(error)
          this.loadingSimpan = false
        })
    },
    cetak() {
      window.open(
        `${apiRoot}/api/Arisan/getTagihanAnggota_bulanan/${this.token}/cetak/${this.editedItem.bulan}/${this.editedItem.tahun}`,
        '_blank',
      )
    },
    export_excel() {
      window.open(
        `${apiRoot}/api/Arisan/getTagihanAnggota_bulanan/${this.token}/excel/${this.editedItem.bulan}/${this.editedItem.tahun}`,
        '_blank',
      )
    },
  },
}
</script>
