<template>
  <v-row class="match-height">
    <v-col md="12" cols="12">
      <v-card>
        <h3 class="px-2 py-2">Tagihan Arisan & Toko Online</h3>
        <v-tabs v-model="tab" class="tab-index">
          <v-tab v-for="item in items" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in items" :key="item" class="mt-2">
            <tagihan-harian v-if="item === 'UPAH HARIAN'"></tagihan-harian>
            <tagihan-mingguan v-if="item === 'UPAH MINGGUAN'"></tagihan-mingguan>
            <tagihan-bulanan v-if="item === 'UPAH BULANAN'"></tagihan-bulanan>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import TagihanHarian from './TagihanHarian2.vue'
import TagihanMingguan from './TagihanMingguan2.vue'
import TagihanBulanan from './TagihanBulanan2.vue'

export default {
  components: {
    TagihanHarian,
    TagihanMingguan,
    TagihanBulanan,
  },
  data() {
    return {
      items: ['UPAH HARIAN', 'UPAH MINGGUAN', 'UPAH BULANAN'],
      tab: null,
    }
  },
}
</script>
